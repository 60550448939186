import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'fff-ok-modal',
  templateUrl: './ok-modal.component.html',
  styleUrls: ['./ok-modal.component.scss']
})
export class OkModalComponent {
  @Input() public message!: String

  constructor(private activesModal:NgbActiveModal) { }

  public confirm(){
    this.activesModal.close()
  }
}
