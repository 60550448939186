import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { catchError, tap, throwError } from 'rxjs'
import { CallService } from 'src/app/api/call.service'
import { CallType } from 'src/app/enums/call-type.enum'
import { AuthService } from 'src/app/services/auth.service'
import { LoadingService } from 'src/app/services/loading.service'

type callModel = {
	id: number
	title: string
	dueDate: Date
	type: CallType
}

@Component({
	selector: 'fff-inscriptions-page',
	templateUrl: './inscriptions-page.component.html',
	styleUrls: ['./inscriptions-page.component.scss'],
})
export class InscriptionsPageComponent implements OnInit {
	public calls: callModel[] = []

	constructor(private router: Router, private loadingService: LoadingService, private callService: CallService, private authService: AuthService) {}

	public ngOnInit(): void {
		this.loadingService.show()
		this.callService
			.getAvailables()
			.pipe(
				tap(() => this.loadingService.hide()),
				tap((x) => {
					this.calls = x.calls.map((y) => ({
						id: Number.parseInt(y.id),
						title: y.description,
						dueDate: y.due_date,
						type: y.type as CallType,
					}))
				}),
				catchError((e) => {
					this.loadingService.hide()
					return throwError(() => e)
				})
			)
			.subscribe()
	}

	public navToAnnouncement(id: number): void {
		if (!this.authService.currentIsUserLogged()) {
			this.router.navigate(['/login'])
			return
		}

		const call = this.calls.find((x) => x.id == id)

		if (call?.type == CallType.AwardAnnual || call?.type == CallType.AwardBiennial) {
			if (this.authService.currentUser().isAdmin) {
				this.router.navigate(['/app/admin/convocatorias/premios/' + id])
				localStorage.setItem('proyectType', call.type.toString())
				return
			}

			this.router.navigate(['/app/usuarios/convocatorias/premios/' + id])
			localStorage.setItem('proyectType', call.type.toString())
		}

		if (call?.type == CallType.Subsidy || call?.type == CallType.Scholarships) {
			if (this.authService.currentUser().isAdmin) {
				this.router.navigate(['/app/admin/convocatorias/subsidios/' + id])
				localStorage.setItem('proyectType', call.type.toString())
				return
			}

			this.router.navigate(['/app/usuarios/convocatorias/subsidios/' + id])
			localStorage.setItem('proyectType', call.type.toString())
		}
	}
}
