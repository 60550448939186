<section class="page container my-4 galery_page">
	<div class="galery_page__container">
		<h6 class="display-6 text-primary text-center">Galería de imágenes</h6>
		<div class="galery_page__menu my-4">
			<div class="galery_page__menu_item" *ngFor="let item of galeryContent,let i = index" (click)="changeTab(i)">{{item.description}}</div>
		</div>
		<div class="galery_page__body">
			<p class="text-primary fs-5" *ngIf="projecType == 1">Premio Anual</p>
			<p class="text-primary fs-5" *ngIf="projecType == 2">Premio Bienal</p>
			<p class="text-primary fs-5" *ngIf="projecType == 3">Subsidio</p>
			<p class="text-primary fs-5" *ngIf="projecType == 4">Beca Estímulo</p>
			<div class="galery_page__images">
				<div class="galery_page__images_item" (click)="openGaleryModal(item)"  *ngFor="let item of images[initPosition]">
					<img src={{item}} />
				</div>
			</div>
		</div>
	</div>
</section>
