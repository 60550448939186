<section class="page container my-4">
	<div class="row">
		<div class="col">
			<h4 class="display-4 text-primary">La Fundación</h4>
			<hr />
			<section>
				<h4 class="text-primary my-4">Objeto</h4>
				<p>
					El objeto de la Fundación es estimular y favorecer la educación, formación profesional e investigación científica, especialmente en el campo
					de la salud humana y cooperar a la beneficencia pública
				</p>
				<p>Para cumplir su objeto la Fundación podrá:</p>
				<ul>
					<li>Organizar o participar en la organización y patrocinio de actos académicos o científicos relacionados con sus objetivos;</li>
					<li>
						Organizar o participar en la organización y patrocinio de congresos, conferencias, jornadas o cursos y actividades similares sobre temas
						afines a su objeto;
					</li>
					<li>
						Efectuar por sí, o participar y patrocinar conjuntamente con otras instituciones el otorgamiento de premios, becas y ayudas de cualquier
						naturaleza a instituciones, estudiantes, profesionales e investigadores;
					</li>
					<li>
						Conceder ayudas y donaciones de cualquier naturaleza, a asilos, hospitales y en general a otras instituciones de asistencia o
						beneficencia públicas o privadas.
					</li>
				</ul>
			</section>
		</div>
		<div class="col">
			<img class="fundation_page__img" src="assets/img/Foto_Doctor.jpg" />
		</div>
	</div>
	<hr />
	<section>
		<h4 class="text-primary my-4">Reseña</h4>
		<p>
			Fundación Florencio Fiorini es una entidad sin fines de lucro instituida por su fundador, Sr. Florencio Fiorini (†) , su esposa Sra. Elvira
			Rodriguez de Fiorini (†) y un grupo de amigos con la intención de continuar las obras que en vida él mismo venía realizando.
		</p>
		<p>
			Fue constituida el 23 de abril de 1991 y obtuvo su personería jurídica por Resolución N° 000522 de la Inspección General de Justicia el 16/7/1991.
		</p>
	</section>
	<hr />
	<section>
		<h4 class="text-primary my-4">Miembros del Consejo de Administración</h4>
		<ul class="list-unstyled">
			<li class="mb-3"><strong class="text-primary">Presidente: </strong>C.P. Carlos A. Martínez</li>
			<li class="mb-3"><strong class="text-primary">Vicepresidente 1°: </strong>Acad. Juan Carlos Bagó</li>
			<li class="mb-3"><strong class="text-primary">Secretaria: </strong>Lic. Mirta L. Déboli</li>
			<li class="mb-3"><strong class="text-primary">Tesorero: </strong>C.P. César Martínez de Yuso</li>
			<li class="mb-3"><strong class="text-primary">Protesorero: </strong>C.P. José E. Castellini</li>
			<li class="mb-3"><strong class="text-primary">Vocal: </strong>Dr. Carlos Álvarez Bermúdez</li>
		</ul>
	</section>
	<hr />
	<section>
		<h4 class="text-primary my-4">Estatuto</h4>
		<p>Para ver el estatuto<a href="{{UrlService.GetResource('estatuto.pdf')}}" target="_blank">(haga click aqui)</a></p>
	</section>
</section>
