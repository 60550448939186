<section class="container my-4">
	<div class="d-flex align-items-center flex-column py-4">
		<div class="row">
			<div class="col-md-2">
				<img src="assets/img/ProgSubsidio.jpg" class="img-fluid rounded-start" alt="..." />
			</div>
			<div class="col-md-10">
				<h2 class="display-4 text-primary"><strong>Subsidio para Investigación en Ciencias Biomédicas</strong></h2>
				<p class="my-2">
					Para profesionales argentinos en ciencias, con aplicación en medicina humana, que deseen realizar sus proyectos de investigación en el país. Se
					otorga en cooperación con la Academia Nacional de Medicina.
				</p>
			</div>
		</div>
	</div>
	<section>
		<h4 class="text-primary my-4">Reseña</h4>
		<p>
			El 13 de diciembre de 2004 la Fundación Florencio Fiorini y la Academia Nacional de Medicina instituyeron un programa anual que se denomina "Subsidios Florencio Fiorini para Investigación en Ciencias Biomédicas", que tiene como objetivo dar apoyo económico para estímulo científico a profesionales biomédicos argentinos, sin límite de edad, para desarrollar en el país proyectos de investigación básica o aplicada en medicina humana.
		</p>
		<p>
			Cada año se convoca a participar de un concurso abierto de proyectos, de entre los que un jurado designado al efecto selecciona a los beneficiarios de los subsidios.
		</p>
		<p>
			Cada subsidio consiste en una suma de dinero a abonarse mensualmente durante un año.
		</p>
	</section>
	<div class="d-flex align-items-center flex-column py-4">
		<button class="btn btn-primary btn-lg mb-4" [routerLink]="['/subsidio']">Inscripción</button>
	</div>
	<section>
		<h4 class="text-primary my-4">Subsidios otorgados desde 2005</h4>
		<ul>
			<li><a href="{{UrlService.GetResource('Listado_Subsidios_Investigacion_desde_2005.pdf')}}" target="_blank">Lista de beneficiarios</a></li>
		</ul>
	</section>
	<hr />
	<section>
		<h4 class="text-primary my-4">Galería de imágenes</h4>
		<!--
		<ul>
			<li>Para ver la galería de imágenes <a [routerLink]="['../galeria']" [queryParams]="{proyectType: 3}">(haga click aqui)</a></li>
		</ul>
		-->
		<p>En construcción</p>
	</section>
</section>
