import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { EMPTY, map, Observable } from 'rxjs'
import { AuthService, AuthState } from 'src/app/services/auth.service'
import { BreakpointEnum, UiService } from 'src/app/services/ui.service'

@Component({
	selector: 'fff-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
	public show: boolean = false

	public showInvestigationMenu: boolean = false
	public isMobileOrTablet$: Observable<boolean> = EMPTY
	public isUserLogged$: Observable<boolean> = EMPTY
	public user$: Observable<AuthState> = EMPTY

	constructor(private uiService: UiService, private authService: AuthService, private router: Router) {
		this.isMobileOrTablet$ = uiService.currentBreakPoint().pipe(map((x) => x === BreakpointEnum.mobile || x === BreakpointEnum.tablet))
		this.isUserLogged$ = authService.isUserLogged()
		this.user$ = authService.user()
	}

	public onClickToggler(): void {
		this.show = !this.show
	}

	public onClickInvestigation(): void {
		this.showInvestigationMenu = !this.showInvestigationMenu
	}

	public logout(): void {
		this.authService.logout()
		this.router.navigate(['/'])
	}

	public goProfile(): void {
		if (this.authService.currentUser().isAdmin) {
			this.router.navigate(['app/admin/profile'])
			return
		}

		this.router.navigate(['app/usuarios/profile'])
		return
	}
}
