<section class="page container my-4">
	<h2 class="display-4 text-primary">Programa de Apoyo al Bien Público</h2>
	<hr />
	<section>
		<h4 class="text-primary my-4">Reseña</h4>
		<p>
			La Fundación ejecuta un programa de apoyo a actividades de bien público cooperando con aportes económicos periódicos a calificadas entidades que
			llevan a cabo obras en beneficio de la comunidad, tales como asistencia a familias sin recursos, a la educación y atención de niños, jóvenes y
			adultos con carencias o necesidades especiales.
		</p>
	</section>
	<hr />
	<section>
		<h4 class="text-primary my-4">Instituciones Beneficiarias</h4>
		<p>
			Para ver las Instituciones Beneficiarias
			<a href="{{UrlService.GetResource('Listado_entidades_datos_institucional.pdf')}}" target="_blank">(haga click aqui)</a>
		</p>
	</section>
	<hr />
	<section>
		<h4 class="text-primary my-4">Galería de Imágenes</h4>
		<!--<p>Para ver la galería de imágenes <a [routerLink]="['../galeria']">(haga click aqui)</a></p>-->
		<p>En construcción...</p>

	</section>
</section>
