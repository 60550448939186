import { Injectable } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	constructor(private ngxSpinnerService: NgxSpinnerService) {}

	public show(): void {
		this.ngxSpinnerService.show()
	}

	public hide(): void {
		this.ngxSpinnerService.hide()
	}
}
