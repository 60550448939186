<section class="page container my-4">
	<div class="row">
		<div class="col">
			<h4 class="display-2 text-primary">Programa de Apoyo a Escuelas Rurales</h4>
			<hr />
			<section>
				<h4 class="text-primary my-4">Reseña</h4>
				<p>
					El 6 de diciembre de 2005 la Fundación Florencio Fiorini y A.P.A.E.R. Asociación Civil Padrinos de Alumnos y Escuelas Rurales instituyeron
					dos programas de cooperación.
				</p>
				<ul>
					<li>
						Programa Becas de Estudio Florencio Fiorini por el cual se beneficia a alumnos de escuelas rurales que por razones de distancia no
						puedan terminar la Enseñanza General Básica (EGB-3). APAER designa a los beneficiarios y el monto de cada beca y la Fundación contribuye
						con el valor asignado a los beneficiarios durante cada período lectivo.
					</li>
					<li>
						Programa Construcción de Escuelas Rurales por el cual se contribuye con el costo de los materiales necesarios para la construcción o
						refacción de escuelas rurales que APAER preselecciona de acuerdo a la importancia de sus necesidades edilicias.
					</li>
				</ul>
			</section>
		</div>
		<div class="col">
			<img class="fundation_page__img" src="assets/img/apaer.jpg" />
		</div>
	</div>
	<hr />
	<section>
		<h4 class="text-primary my-4">Becas de Estudio</h4>
		<p>
			Beneficiarios Programa Becas Alumnos Escuelas Rurales
			<a href="{{UrlService.GetResource('Informe_becas_escuelas_rurales.pdf')}}" target="_blank">(haga click aqui)</a>
		</p>
	</section>
	<hr />
	<section>
		<h4 class="text-primary my-4">Construcción</h4>
		<p>
			Beneficiarios Programa Construcción Escuelas Rurales
			<a href="{{UrlService.GetResource('Programa_construcción_escuelas_rurales_desde_2005_p_Web.pdf')}}" target="_blank">(haga click aqui)</a>
		</p>
	</section>
	<hr />
	<section>
		<h4 class="text-primary my-4">Galería de Imágenes</h4>
		<!--<p>Para ver la galería de imágenes <a [routerLink]="['../galeria']">(haga click aqui)</a></p>-->
		<p>En construcción...</p>
	</section>
</section>
