<section class="container my-4">
	<div class="d-flex align-items-center flex-column py-4">
		<div class="row">
			<div class="col-md-2">
				<img src="assets/img/ProgAnual.jpg" class="img-fluid rounded-start" alt="..." />
			</div>
			<div class="col-md-10">
				<h2 class="display-4 text-primary"><strong> Premio Anual sobre Avances en Especialidad Médica</strong></h2>
				<p class="my-2">
					Para profesionales argentinos en ciencias médicas que hayan logrado un avance en la especialidad médica de la convocatoria. Se otorga en cooperación con la Universidad del Salvador.
				</p>
			</div>
		</div>
	</div>
	<section>
		<h4 class="text-primary my-4">Reseña</h4>
		<p>
			En diciembre de 1992 la Fundación Florencio Fiorini y la Universidad del Salvador instituyeron un Premio Anual denominado "Premio Anual Florencio Fiorini", que tiene como objetivo estimular y favorecer la investigación científica en medicina humana.
		</p>
		<p>
			Este premio anual distingue al mejor trabajo científico que se realice en el país.
Cada año se elige una especialidad y se convoca a la comunidad médica a participar con sus trabajos.
		</p>
	</section>
	<div class="d-flex align-items-center flex-column py-4">
		<button class="btn btn-primary btn-lg mb-4" [routerLink]="['/premioanual']">Inscripción</button>
	</div>
	<hr />
	<section>
		<h4 class="text-primary my-4">Premios otorgados desde 1993</h4>
		<ul>
			<li><a target="_blank" href="{{UrlService.GetResource('Premiados_PA_Lista_premiados_trabajos_y_jurado_93_hoy.pdf')}}">Lista de beneficiarios</a></li>
		</ul>
	</section>
	<hr />
	<section>
		<h4 class="text-primary my-4">Galería de imágenes</h4>
		<!--
		<ul>
			<li>Para ver la galería de imágenes <a [routerLink]="['../galeria']" [queryParams]="{proyectType: 1}">(haga click aqui)</a></li>
		</ul>
		-->
		<p>En construcción</p>
	</section>
	<hr />
</section>
