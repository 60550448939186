import { Injectable } from '@angular/core'
import { BehaviorSubject, map, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'


export enum AdminLevel {
	None = 0,
	Viewer = 1,
	Complete = 2
}

export interface AuthState {
	id?: number
	token?: string
	fullName?: string
	isAdmin?: boolean
	adminLevel?: AdminLevel
	email?: string

}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private state: BehaviorSubject<AuthState> = new BehaviorSubject({})

	constructor() {
		const localStorageData = localStorage.getItem(environment.localStorageKey)
		if (localStorageData) {
			const payload = JSON.parse(atob(localStorageData))
			this.state.next({
				id: payload['id'],
				token: payload['token'],
				fullName: payload['fullName'],
				isAdmin: payload['isAdmin'],
				adminLevel: payload['adminLevel'],
				email: payload['email'],
			})
		}
	}

	public loginUser(payload: AuthState) {
		localStorage.setItem(environment.localStorageKey, btoa(JSON.stringify(payload)))
		this.state.next(payload)
	}

	public logout(): void {
		localStorage.removeItem(environment.localStorageKey)
		this.state.next({})
	}

	public isUserLogged(): Observable<boolean> {
		return this.state.asObservable().pipe(
			map((x) => {
				for (var i in x) return true
				return false
			})
		)
	}

	public user(): Observable<AuthState> {
		return this.state.asObservable()
	}

	public currentIsUserLogged(): boolean {
		return !!this.state.getValue().token
	}

	public currentUser(): AuthState {
		return this.state.getValue()
	}
}
