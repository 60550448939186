import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { tap } from 'rxjs'
import { CallService } from 'src/app/api/call.service'
import { UrlService } from 'src/app/api/url.service'
import { SubsidyModel } from 'src/app/interfaces/subsidy.interface'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'fff-subsidy',
	templateUrl: './subsidy-review-page.component.html',
	styleUrls: ['./subsidy-review-page.component.scss'],
})
export class SubsidyReviewPageComponent implements OnInit {
	
	public subsidy!: SubsidyModel[]
	public noProject: boolean = false
	public firstDueDate: string = "..."
	private projectNumber: number = 3
	public UrlService: UrlService

	constructor(
		private router: Router,
		private activatedRouter: ActivatedRoute,
		private callService: CallService,
		private urlService: UrlService)
	{ 
		this.UrlService = urlService
	}

	public ngOnInit() {
		this.callService.getAvailablesType(this.projectNumber).pipe(
			tap(
				(x: any) => {
					if (x.calls.length > 0) {
						this.subsidy = x.calls.map((y: any) => ({
							id: y.id,
							description: y.description,
							due_date: y.due_date,
							type: y.type,
							afichePdfUrl: environment.apiUrl + `call/poster/${y.id}`,
							termsAndConditionsPdfUrl: environment.apiUrl + `call/terms-and-conditions/${y.id}`
						}))
					} else {
						this.noProject = true
					}
				}
			),
			tap(()=>{
				if(this.subsidy!=null)
				{
					const dueDate = new Date(this.subsidy[0].due_date)
					this.firstDueDate = 
						String(dueDate.getDate()).padStart(2, "0") + "/" + 
						String(dueDate.getMonth()+1).padStart(2, "0") + "/" + 
						dueDate.getFullYear()
				}
			})
		).subscribe()
	}

	public onNewInscription(): void {

		this.router.navigate([`/app/usuarios/convocatorias/subsidios/${this.subsidy[0].id}`], { })
		localStorage.setItem('proyectType', this.projectNumber.toString())
	}
}
