<section class="page container my-4 login_page">
	<h4 class="display-4 text-primary">Reseteo de contraseña</h4>
	<form [formGroup]="form" class="login_page__form border p-4 mt-4">
		<div class="form-group mt-4">
			<input
				formControlName="newPassword"
				type="password"
				class="form-control"
				placeholder="Password"
				[ngClass]="{ 'is-invalid': submitted && form.get('newPassword')?.errors }"
			/>
			<span *ngIf="submitted && form.get('newPassword')?.errors" class="invalid-feedback">{{
				form.get('newPassword')?.hasError('newPassword') ? 'Ingrese una password válida.' : 'Ingrese password.'
			}}</span>
		</div>
		<div class="form-group">
			<input
				formControlName="validateNewPassword"
				type="password"
				class="form-control"
				placeholder="Confirmación password"
				[ngClass]="{ 'is-invalid': submitted && form.get('validateNewPassword')?.errors }"
			/>
			<span *ngIf="submitted && form.get('validateNewPassword')?.errors" class="invalid-feedback">Ingrese clave.</span>
		</div>
		<button type="button" (click)="login()" class="btn btn-primary">Confirmar</button>
		<button type="button" (click)="cancelar()" class="btn btn-danger">Cancelar</button>
	</form>
</section>
