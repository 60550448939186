import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgbCarouselModule, NgbCollapseModule, NgbDateAdapter, NgbDateParserFormatter, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { DatePipe } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faFemale, faMapMarkedAlt, faPhoneAlt, faRightFromBracket, faUser, fas } from '@fortawesome/free-solid-svg-icons'
import { NgxSpinnerModule } from 'ngx-spinner'
import { tokenInterceptorProvider } from 'src/interceptors/token.interceptor'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { FooterComponent } from './components/footer/footer.component'
import { GaleryModalComponent } from './components/galery-modal/galery-modal.component'
import { NavbarComponent } from './components/navbar/navbar.component'
import { PrivateGuard } from './guards/private.guard'
import { PublicGuard } from './guards/public.guard'
import { AnnualAwardPageComponent } from './pages/annual-award-page/annual-award-page.component'
import { BiennialAwardPageComponent } from './pages/biennial-award-page/biennial-award-page.component'
import { ContactPageComponent } from './pages/contact-page/contact-page.component'
import { FundationPageComponent } from './pages/fundation-page/fundation-page.component'
import { GaleryPageComponent } from './pages/galery-page/galery-page.component'
import { HomePageComponent } from './pages/home-page/home-page.component'
import { InscriptionsPageComponent } from './pages/inscriptions-page/inscriptions-page.component'
import { LoginPageComponent } from './pages/login-page/login-page.component'
import { PublicGoodPageComponent } from './pages/public-good-page/public-good-page.component'
import { RegisterPageComponent } from './pages/register-page/register-page.component'
import { ScholarshipPageComponent } from './pages/scholarship-page/scholarship-page.component'
import { SchoolsPageComponent } from './pages/schools-page/schools-page.component'
import { SubsidyPageComponent } from './pages/subsidy-page/subsidy-page.component'
import { CustomAdapter, CustomDateParserFormatter } from './utils/date-picker-format'
import { ScholarshipReviewPageComponent } from './pages/scholarship-review-page/scholarship-review-page.component'
import { BiennialAwardReviewPageComponent } from './pages/biennial-award-review-page/biennial-award-review-page.component'
import { AnnualAwardReviewPageComponent } from './pages/annual-award-review-page/annual-award-review-page.component'
import { SubsidyReviewPageComponent } from './pages/subsidy-review-page/subsidy-review-page.component'
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component'
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component'

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		HomePageComponent,
		ScholarshipPageComponent,
		SubsidyPageComponent,
		AnnualAwardPageComponent,
		BiennialAwardPageComponent,
		FooterComponent,
		InscriptionsPageComponent,
		FundationPageComponent,
		SchoolsPageComponent,
		PublicGoodPageComponent,
		ContactPageComponent,
		LoginPageComponent,
		RegisterPageComponent,
		GaleryPageComponent,
		GaleryModalComponent,
		ScholarshipReviewPageComponent,
		BiennialAwardReviewPageComponent,
		AnnualAwardReviewPageComponent,
		SubsidyReviewPageComponent,
		ResetPasswordComponent,
		PasswordRecoveryComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		NgbCarouselModule,
		NgbModule,
		FontAwesomeModule,
		NgbCollapseModule,
		NgbDropdownModule,
		NgxSpinnerModule,
		ReactiveFormsModule,
		FormsModule,
		BrowserAnimationsModule,
	],
	providers: [
		PublicGuard,
		PrivateGuard,
		DatePipe,
		tokenInterceptorProvider,
		{
			provide: NgbDateAdapter,
			useClass: CustomAdapter
		},
		{
			provide: NgbDateParserFormatter,
			useClass: CustomDateParserFormatter
		}
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(faPhoneAlt, faEnvelope, faFemale, faMapMarkedAlt, faTwitter, faFacebookF, faInstagram, faLinkedinIn, faUser, faRightFromBracket)
		library.addIconPacks(fas)
	}
}
