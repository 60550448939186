import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

type callResponse = {
	id: string
	description: string
	due_date: Date
	type: number
}

type callRequest = {
	description: string
	type: number
	due_date: Date
	opening_date: Date
	enable: number
	order: number
	order_year: number
}

@Injectable({
	providedIn: 'root',
})
export class CallService {
	private readonly baseUrl = 'call'
	constructor(private httpClient: HttpClient) {
		console.log("this.baseUrl")
	}

	public getAvailables(): Observable<{ calls: callResponse[] }> {
		return this.httpClient.get(environment.apiUrl + this.baseUrl + '/availables').pipe(map((x) => x as { calls: callResponse[] }))
	}

	public getAvailablesType(type:number): Observable<{ calls: callResponse[] }> {
		return this.httpClient.get(environment.apiUrl + this.baseUrl + `/availables/${type}`).pipe(map((x) => x as { calls: callResponse[] }))
	}

	 public getTermAndConditions(id: number): Observable<any> {
		return this.httpClient.get(environment.apiUrl + `terms-and-conditions/${id}`)
	}

	public create(request: callRequest): Observable<{
		id: string
	}> {
		return this.httpClient.post(environment.apiUrl + this.baseUrl, request).pipe(map((x) => x as { id: string }))
	}

	public update(id: number, request: callRequest): Observable<any> {
		return this.httpClient.patch(environment.apiUrl + this.baseUrl + `/${id}`, request)
	}

	public getPoster(id:number):Observable<any>{
		return this.httpClient.get(environment.apiUrl + this.baseUrl + `/poster/${id}`)
	}
}
