import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { tap } from 'rxjs'
import { CallService } from 'src/app/api/call.service'
import { AwardModel } from 'src/app/interfaces/award.interface'
import { environment } from 'src/environments/environment'


@Component({
	selector: 'fff-biennial-award-page',
	templateUrl: './biennial-award-page.component.html',
	styleUrls: ['./biennial-award-page.component.scss'],
})
export class BiennialAwardPageComponent implements OnInit {

	public bienalAwards!:AwardModel[]
	public noProject: boolean = false
	public firstDueDate: string = "..."
	public projectType: number = 4

	constructor(private router:Router,private activatedRouter:ActivatedRoute,private callService:CallService){}

	public ngOnInit():void {
		this.callService.getAvailablesType(this.projectType).pipe(
			tap(
				(x: any) => {
					if (x.calls.length > 0) {
						console.log(x.calls)
						this.bienalAwards = x.calls.map((y: any) => ({
							id: y.id,
							description: y.description,
							due_date: y.due_date,
							type: y.type,
							afichePdfUrl: environment.apiUrl + `call/poster/${y.id}`,
							termsAndConditionsPdfUrl: environment.apiUrl + `call/terms-and-conditions/${y.id}`
						}))
						
					} else {
						this.noProject = true
					}
				}
			),
			tap(()=>{
				if(this.bienalAwards!=null)
				{
					const dueDate = new Date(this.bienalAwards[0].due_date)
					this.firstDueDate = 
						String(dueDate.getDate()).padStart(2, "0") + "/" + 
						String(dueDate.getMonth()+1).padStart(2, "0") + "/" + 
						dueDate.getFullYear()
				}
			})
		).subscribe()

	}
	public onNewInscription(): void {
		this.router.navigate([`/app/usuarios/convocatorias/premios/${this.bienalAwards[0].id}`], {
			relativeTo: this.activatedRouter
		})
		localStorage.setItem('proyectType',this.projectType.toString())
	}
}
