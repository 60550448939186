import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PrivateGuard } from './guards/private.guard'
import { AnnualAwardPageComponent } from './pages/annual-award-page/annual-award-page.component'
import { BiennialAwardPageComponent } from './pages/biennial-award-page/biennial-award-page.component'
import { ContactPageComponent } from './pages/contact-page/contact-page.component'
import { FundationPageComponent } from './pages/fundation-page/fundation-page.component'
import { GaleryPageComponent } from './pages/galery-page/galery-page.component'
import { HomePageComponent } from './pages/home-page/home-page.component'
import { InscriptionsPageComponent } from './pages/inscriptions-page/inscriptions-page.component'
import { LoginPageComponent } from './pages/login-page/login-page.component'
import { PublicGoodPageComponent } from './pages/public-good-page/public-good-page.component'
import { RegisterPageComponent } from './pages/register-page/register-page.component'
import { ScholarshipPageComponent } from './pages/scholarship-page/scholarship-page.component'
import { SchoolsPageComponent } from './pages/schools-page/schools-page.component'
import { SubsidyPageComponent } from './pages/subsidy-page/subsidy-page.component'
import { BiennialAwardReviewPageComponent } from './pages/biennial-award-review-page/biennial-award-review-page.component'
import { AnnualAwardReviewPageComponent } from './pages/annual-award-review-page/annual-award-review-page.component'
import { SubsidyReviewPageComponent } from './pages/subsidy-review-page/subsidy-review-page.component'
import { ScholarshipReviewPageComponent } from './pages/scholarship-review-page/scholarship-review-page.component'
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component'
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component'

const routes: Routes = [
	{
		path: '',
		component: HomePageComponent,
	},
	{
		path: 'becaestimulo',
		component: ScholarshipPageComponent,
	},
	{
		path: 'becaestimulo-review',
		component: ScholarshipReviewPageComponent,
	},
	{
		path: 'subsidio',
		component: SubsidyPageComponent,
	},
	{
		path: 'subsidio-review',
		component: SubsidyReviewPageComponent,
	},
	{
		path: 'premioanual',
		component: AnnualAwardPageComponent,
	},
	{
		path: 'premioanual-review',
		component: AnnualAwardReviewPageComponent,
	},
	{
		path: 'premiobienal',
		component: BiennialAwardPageComponent,
	},
	{
		path: 'premiobienal-review',
		component: BiennialAwardReviewPageComponent,
	},
	{
		path: 'inscripciones',
		component: InscriptionsPageComponent,
	},
	{
		path: 'fundacion',
		component: FundationPageComponent,
	},
	{
		path: 'escuelasrurales',
		component: SchoolsPageComponent,
	},
	{
		path: 'bienpublico',
		component: PublicGoodPageComponent,
	},
	{
		path: 'contacto',
		component: ContactPageComponent,
	},
	{
		path: 'login',
		component: LoginPageComponent,
	},
	{
		path: 'registro',
		component: RegisterPageComponent,
	},
	{
		path: 'galeria',
		component: GaleryPageComponent,
	},
	{
		path: 'password-recovery',
		component: PasswordRecoveryComponent,
	},
	{
		path: 'reset-password/:userid/:recoverycode',
		component: ResetPasswordComponent,
	},
	{
		path: 'app',
		canActivate: [PrivateGuard],
		children: [
			{
				path: 'admin',
				loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
			},
			{
				path: 'usuarios',
				loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
			},
		],
	},
	{
		path: '*',
		redirectTo: '',
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
