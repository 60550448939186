import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { AuthService } from '../services/auth.service'

@Injectable()
export class PublicGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const can = !this.authService.currentIsUserLogged()

		if (!can) {
			this.router.navigate(['/app'])
		}

		return can
	}
}
