<section class="page container my-4">
	<h4 class="display-4 text-primary">Contacto</h4>
	<hr />
	<section>
		<ul class="list-unstyled">
			<li>Cuba 1940 P8 Of. 803, C1428AED Ciudad Autónoma de Buenos Aires.</li>
			<li>Tel/Fax: (011) 4780-2679 / (011) 60070742</li>
			<li>Email: secretaria@fundacionfiorini.org.ar</li>
			<li>Web: www.fff.org.ar</li>
			<li>Secretaría: Alicia B. Luchetti</li>
		</ul>
	</section>
</section>
