import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { loginRequest, loginResponse, userFilterRequest, userRequest, userResponse } from '../interfaces/user.interface'


@Injectable({
	providedIn: 'root',
})
export class RecoveryService {
	private readonly baseUrl = 'recovery'
	constructor(private httpClient: HttpClient) {}

	public resetRequest(user: String): Observable<any> {
		return this.httpClient.post(
			environment.apiUrl + this.baseUrl + '/reset-request', {user: user}
		)
	}

	public confirmReset(
		identifier: number,
		code: number,
		newPassword: String,
		validateNewPassword: String
	): Observable<any> {
		return this.httpClient.post(
			environment.apiUrl + this.baseUrl + '/confirm-reset/' + identifier.toString() + "/" + code.toString(),
			{ 
				"new_password": newPassword, 
				"validate_new_password": validateNewPassword, 
			})
	}

}
