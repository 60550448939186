import { Component, Input } from '@angular/core'

@Component({
	selector: 'fff-galery-modal',
	templateUrl: './galery-modal.component.html',
	styleUrls: ['./galery-modal.component.scss'],
})
export class GaleryModalComponent {
	@Input() images: string = ''
	constructor() {}
}
