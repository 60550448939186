import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { loginRequest, loginResponse, userFilterRequest, userRequest, userResponse } from '../interfaces/user.interface'


@Injectable({
	providedIn: 'root',
})
export class UserService {
	private readonly baseUrl = 'user'
	constructor(private httpClient: HttpClient) {}

	public register(request: userRequest): Observable<{
		id: string
	}> {
		return this.httpClient.post(environment.apiUrl + this.baseUrl, request).pipe(
			map(
				(x) =>
					x as {
						id: string
					}
			)
		)
	}

	public login(request: loginRequest): Observable<loginResponse> {
		return this.httpClient.post(environment.apiUrl + this.baseUrl + '/auth', request).pipe(map((x) => x as loginResponse))
	}

	public getById(id: number): Observable<userResponse> {
		return this.httpClient.get(environment.apiUrl + this.baseUrl + '/' + id).pipe(map((x) => x as userResponse))
	}

	public update(id: number, request: userRequest): Observable<string> {
		return this.httpClient.patch(environment.apiUrl + this.baseUrl + '/' + id, request).pipe(map((x) => x as string))
	}

	public get(filter?: userFilterRequest): Observable<any> {
		return this.httpClient.post(environment.apiUrl + this.baseUrl + '/filter', filter)
	}
}
