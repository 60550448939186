<div class="fff-modal">
    <div class="row">
        <div class="col" class="my-4 announcement_form">
            <p class="fs-5">
                {{message}}
            </p>
        </div>
    </div>
    <div class="d-flex justify-content-center ">
        <button type="button" class="btn btn-primary me-3" (click)="confirm()">Aceptar</button>
    </div>
</div>