<section class="page container my-4 login_page">
	<h4 class="display-4 text-primary">Login</h4>
	<form [formGroup]="form" class="login_page__form border p-4 mt-4">
		<div class="form-group mt-4">
			<input
				formControlName="user"
				type="text"
				class="form-control"
				placeholder="Documento / Email"
				[ngClass]="{ 'is-invalid': submitted && form.get('user')?.errors }"
			/>
			<span *ngIf="submitted && form.get('user')?.errors" class="invalid-feedback">{{
				form.get('user')?.hasError('user') ? 'Ingrese un documento o email válido.' : 'Ingrese documento / email.'
			}}</span>
		</div>
		<div class="form-group">
			<input
				formControlName="password"
				type="password"
				class="form-control"
				placeholder="Clave"
				[ngClass]="{ 'is-invalid': submitted && form.get('password')?.errors }"
			/>
			<span *ngIf="submitted && form.get('password')?.errors" class="invalid-feedback">Ingrese clave.</span>
		</div>
		<button type="button" (click)="login()" class="btn btn-primary">Ingresar</button>
		<p class="text-center mb-4"><a [routerLink]="['/password-recovery']">¿Olvidó la contraseña?</a> / <a [routerLink]="['/registro']"> Registrarse</a></p>
	</form>
</section>
