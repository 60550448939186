<section class="page container my-4 register_page">
	<h4 class="display-4 text-primary">Registro</h4>
	<div class="register_page__container border p-4 mt-4">
		<div class="row">
			<div *ngFor="let step of steps; let i = index" class="col-md-4 col-lg-4">
				<div class="progress my-3" style="height: 1px">
					<div
						class="progress-bar"
						role="progressbar"
						[ngClass]="{ 'progress-active': currentStep >= i + 1, 'progress-inactive': currentStep < i + 1 }"
						aria-valuenow="100"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
				<div class="progress my-3" style="height: 20px">
					<div
						class="progress-bar"
						[ngClass]="{ 'progress-bar-inactives': currentStep < i + 1 }"
						role="progressbar"
						style="width: 100%"
						aria-valuenow="100"
						aria-valuemin="0"
						aria-valuemax="100"
					>
						{{ step }}
					</div>
				</div>
			</div>
		</div>
		<div class="my-3 w-100">
			<div class="register_page__step_content">
				<!-- Paso 1 -->
				<form [formGroup]="formStep1" *ngIf="currentStep === 1">
					<div class="form-group row">
						<label class="col-3 col-form-label">Tipo de documento</label>
						<div class="col">
							<select
								formControlName="dniType"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep1 && formStep1.get('dniType')?.errors }"
							>
								<option>DNI</option>
								<option>DU</option>
								<option>LE</option>
							</select>
							<span *ngIf="submittedStep1 && formStep1.get('dniType')?.errors" class="invalid-feedback">Seleccione tipo de DNI.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Documento</label>
						<div class="col">
							<input
								formControlName="dni"
								type="text"
								class="form-control"
								(keypress)="inputNumberValidation($event)"
								maxlength="9"
								[ngClass]="{ 'is-invalid': submittedStep1 && formStep1.get('dni')?.errors }"
							/>
							<span *ngIf="submittedStep1 && formStep1.get('dni')?.errors" class="invalid-feedback">Ingrese DNI.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Repetir documento</label>
						<div class="col">
							<input
								formControlName="repeatedDni"
								type="text"
								class="form-control"
								(keypress)="inputNumberValidation($event)"
								[ngClass]="{ 'is-invalid': submittedStep1 && formStep1.get('repeatedDni')?.errors }"
							/>
							<span *ngIf="submittedStep1 && formStep1.get('repeatedDni')?.errors" class="invalid-feedback">
								{{ formStep1.get('repeatedDni')?.hasError('matching') ? 'DNI no coinciden' : 'Ingrese DNI' }}
							</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Correo Electrónico</label>
						<div class="col">
							<input
								formControlName="email"
								type="email"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep1 && formStep1.get('email')?.errors }"
							/>
							<span *ngIf="submittedStep1 && formStep1.get('email')?.errors" class="invalid-feedback">
								{{ formStep1.get('email')?.hasError('email') ? 'Ingrese email valido' : 'Ingrese email' }}
							</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Repetir Correo Electrónico</label>
						<div class="col">
							<input
								formControlName="repeatedEmail"
								type="email"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep1 && formStep1.get('repeatedEmail')?.errors }"
							/>
							<span *ngIf="submittedStep1 && formStep1.get('repeatedEmail')?.errors" class="invalid-feedback">
								{{
									formStep1.get('repeatedEmail')?.hasError('email')
										? 'Ingrese email valido'
										: formStep1.get('repeatedEmail')?.hasError('matching')
										? 'Emails no coinciden'
										: 'Ingrese email'
								}}
							</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Clave</label>
						<div class="col">
							<input
								formControlName="password"
								type="password"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep1 && formStep1.get('password')?.errors }"
							/>
							<span *ngIf="submittedStep1 && formStep1.get('password')?.errors" class="invalid-feedback">Ingrese clave.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Repetir Clave</label>
						<div class="col">
							<input
								formControlName="repeatedPassword"
								type="password"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep1 && formStep1.get('repeatedPassword')?.errors }"
							/>
							<span *ngIf="submittedStep1 && formStep1.get('repeatedPassword')?.errors" class="invalid-feedback">
								{{ formStep1.get('repeatedPassword')?.hasError('matching') ? 'Claves no coinciden' : 'Ingrese clave' }}
							</span>
						</div>
					</div>
					<div class="d-flex flex-row-reverse">
						<button type="button" class="btn btn-success" (click)="onSubmitStep1()">Continuar</button>
					</div>
				</form>
				<!-- Paso 2 -->
				<form [formGroup]="formStep2" *ngIf="currentStep === 2">
					<div class="form-group row">
						<label class="col-3 col-form-label">Nombre</label>
						<div class="col">
							<input
								formControlName="firstName"
								type="text"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('firstName')?.errors }"
							/>
							<span *ngIf="submittedStep2 && formStep2.get('firstName')?.errors" class="invalid-feedback">Ingrese nombre.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Apellido</label>
						<div class="col">
							<input
								formControlName="lastName"
								type="text"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('lastName')?.errors }"
							/>
							<span *ngIf="submittedStep2 && formStep2.get('lastName')?.errors" class="invalid-feedback">Ingrese apellido.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Fecha de Nacimiento</label>
						<div class="col">
							<div class="input-group">
								<input
									class="form-control"
									placeholder="dd-mm-aaaa"
									name="dp"
									ngbDatepicker
									formControlName="birthdate"
									#d="ngbDatepicker"
									[minDate]="minDate"
								/>
								<button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
									<fa-icon [icon]="['fas', 'calendar-days']"></fa-icon>
								</button>
							</div>
							<!-- <input
								formControlName="birthdate"
								type="date"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('birthdate')?.errors }"
							/> -->
							<span *ngIf="submittedStep2 && formStep2.get('birthdate')?.errors" class="invalid-feedback">Ingrese fecha de nacimiento.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Domicilio</label>
						<div class="col">
							<input
								formControlName="address"
								type="text"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('address')?.errors }"
							/>
							<span *ngIf="submittedStep2 && formStep2.get('address')?.errors" class="invalid-feedback">Ingrese domicilio.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Código Postal</label>
						<div class="col">
							<input
								formControlName="zipCode"
								type="text"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('zipCode')?.errors }"
								maxlength="10"
							/>
							<span *ngIf="submittedStep2 && formStep2.get('zipCode')?.errors" class="invalid-feedback">Ingrese código postal.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Localidad</label>
						<div class="col">
							<input
								formControlName="city"
								type="text"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('city')?.errors }"
							/>
							<span *ngIf="submittedStep2 && formStep2.get('city')?.errors" class="invalid-feedback">Ingrese localidad.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Provincia</label>
						<div class="col">
							<select formControlName="state" class="form-control" 
							[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('state')?.errors }">
								<option selected hidden></option>
								<option *ngFor="let state of states" [ngValue]="state">{{ state }}</option>
							</select>
							<span *ngIf="submittedStep2 && formStep2.get('state')?.errors" class="invalid-feedback">Seleccione provincia.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Nacionalidad</label>
						<div class="col">
							<input
								formControlName="nationality"
								type="text"
								class="form-control"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('nationality')?.errors }"
							/>
							<span *ngIf="submittedStep2 && formStep2.get('nationality')?.errors" class="invalid-feedback">Ingrese nacionalidad.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Teléfono de Contacto</label>
						<div class="col-2">
							<input
								formControlName="areaCodePhone"
								type="text"
								class="form-control"
								placeholder="Area"
								(keypress)="inputNumberValidation($event)"
								maxlength="5"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('areaCodePhone')?.errors }"
							/>
							<span *ngIf="submittedStep2 && formStep2.get('areaCodePhone')?.errors" class="invalid-feedback">Ingrese área.</span>
						</div>
						<div class="col">
							<input
								formControlName="numberPhone"
								type="text"
								class="form-control"
								placeholder="Teléfono"
								(keypress)="inputNumberValidation($event)"
								maxlength="10"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('numberPhone')?.errors }"
							/>
							<span *ngIf="submittedStep2 && formStep2.get('numberPhone')?.errors" class="invalid-feedback">Ingrese teléfono.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Teléfono Alternativo</label>
						<div class="col-2">
							<input
								formControlName="areaCodePhoneAlt"
								type="text"
								class="form-control"
								placeholder="Area"
								(keypress)="inputNumberValidation($event)"
								maxlength="5"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('areaCodePhoneAlt')?.errors }"
							/>
							<span *ngIf="submittedStep2 && formStep2.get('areaCodePhoneAlt')?.errors" class="invalid-feedback">Ingrese área.</span>
						</div>
						<div class="col">
							<input
								formControlName="numberPhoneAlt"
								type="text"
								class="form-control"
								placeholder="Teléfono"
								(keypress)="inputNumberValidation($event)"
								maxlength="10"
								[ngClass]="{ 'is-invalid': submittedStep2 && formStep2.get('numberPhoneAlt')?.errors }"
							/>
							<span *ngIf="submittedStep2 && formStep2.get('numberPhoneAlt')?.errors" class="invalid-feedback">Ingrese teléfono.</span>
						</div>
					</div>
					<div class="d-flex flex-row-reverse">
						<button type="button" class="btn btn-success" (click)="onSubmitStep2()">Continuar</button>
						<div class="px-2">
							<button type="button" class="btn btn-secondary" (click)="onBackStep2()">Regresar</button>
						</div>
					</div>
				</form>
				<!-- Paso 3 -->
				<form [formGroup]="formStep3" *ngIf="currentStep === 3">
					<div class="form-group row">
						<label class="col-3 col-form-label">Título Universitario</label>
						<div class="col">
							<input
								formControlName="univertisyDegree"
								type="text"
								class="form-control"
								placeholder="Titulo"
								[ngClass]="{ 'is-invalid': submittedStep3 && formStep3.get('univertisyDegree')?.errors }"
							/>
							<span *ngIf="submittedStep3 && formStep3.get('univertisyDegree')?.errors" class="invalid-feedback">Ingrese titulo.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Universidad que lo expidió</label>
						<div class="col">
							<input
								formControlName="univertisyIssued"
								type="text"
								class="form-control"
								placeholder="Universidad"
								[ngClass]="{ 'is-invalid': submittedStep3 && formStep3.get('univertisyIssued')?.errors }"
							/>
							<span *ngIf="submittedStep3 && formStep3.get('univertisyIssued')?.errors" class="invalid-feedback">Ingrese universidad.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Año de Egreso</label>
						<div class="col">
							<input
								formControlName="graduationYear"
								type="text"
								class="form-control"
								placeholder="Año"
								(keypress)="inputNumberValidation($event)"
								maxlength="4"
								[ngClass]="{ 'is-invalid': submittedStep3 && formStep3.get('graduationYear')?.errors }"
							/>
							<span *ngIf="submittedStep3 && formStep3.get('graduationYear')?.errors" class="invalid-feedback">Ingrese año.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Número de Matrícula</label>
						<div class="col">
							<input
								formControlName="registerNumber"
								type="text"
								class="form-control"
								placeholder="Matricula"
								maxlength="15"
								[ngClass]="{ 'is-invalid': submittedStep3 && formStep3.get('registerNumber')?.errors }"
							/>
							<span *ngIf="submittedStep3 && formStep3.get('registerNumber')?.errors" class="invalid-feedback">Ingrese matrícula.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Lugar de Trabajo</label>
						<div class="col">
							<input
								formControlName="workplace"
								type="text"
								class="form-control"
								placeholder="Lugar de trabajo"
								[ngClass]="{ 'is-invalid': submittedStep3 && formStep3.get('workplace')?.errors }"
							/>
							<span *ngIf="submittedStep3 && formStep3.get('workplace')?.errors" class="invalid-feedback">Ingrese lugar de trabajo.</span>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-3 col-form-label">Cargo o Posición Actual</label>
						<div class="col">
							<input
								formControlName="position"
								type="text"
								class="form-control"
								placeholder="Cargo"
								[ngClass]="{ 'is-invalid': submittedStep3 && formStep3.get('position')?.errors }"
							/>
							<span *ngIf="submittedStep3 && formStep3.get('position')?.errors" class="invalid-feedback">Ingrese cargo.</span>
						</div>
					</div>
					<div class="d-flex flex-row-reverse">
						<button type="button" class="btn btn-success" (click)="onSubmitStep3()">Registrarse</button>
						<div class="px-2">
							<button type="button" class="btn btn-secondary" (click)="onBackStep3()">Regresar</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</section>
