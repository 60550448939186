import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { tap } from 'rxjs'
import { CallService } from 'src/app/api/call.service'
import { SubsidyModel } from 'src/app/interfaces/subsidy.interface'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'fff-scholarship',
	templateUrl: './scholarship-page.component.html',
	styleUrls: ['./scholarship-page.component.scss'],
})
export class ScholarshipPageComponent implements OnInit {

	public scolarships!: SubsidyModel[]
	public noProject: boolean = false
	public firstDueDate: string = "..."
	public projectType: number = 1

	constructor(private router: Router, private activatedRouter: ActivatedRoute, private callService: CallService) { }

	public ngOnInit() {
		this.callService.getAvailablesType(this.projectType).pipe(

			tap(
				(x: any) => {
					if (x.calls.length > 0) {
						
						this.scolarships = x.calls.map((y: any) => ({
							id: y.id,
							description: y.description,
							due_date: y.due_date,
							type: y.type,
							afichePdfUrl: environment.apiUrl + `call/poster/${y.id}`,
							termsAndConditionsPdfUrl: environment.apiUrl + `call/terms-and-conditions/${y.id}`
						}))

					} else {
						this.noProject = true
					}
				}
			),
			tap(()=>{

				if(this.scolarships!=null)
				{
					const dueDate = new Date(this.scolarships[0].due_date)

					this.firstDueDate = 
						String(dueDate.getDate()).padStart(2, "0") + "/" + 
						String(dueDate.getMonth()+1).padStart(2, "0") + "/" + 
						dueDate.getFullYear()
				}

			})
		).subscribe()
	}

	public onNewInscription(): void {
		this.router.navigate([`/app/usuarios/convocatorias/subsidios/${this.scolarships[0].id}`], {
			relativeTo: this.activatedRouter
		})
		localStorage.setItem('proyectType', this.projectType.toString())
	}
}
