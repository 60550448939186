<section class="page container my-4 login_page">
	<h4 class="display-5 text-primary">Recuperación de contraseña</h4>
	<form [formGroup]="form" class="login_page__form border p-4 mt-4">
		<div class="form-group mt-4">
			<input
				formControlName="user"
				type="user"
				class="form-control"
				placeholder="Documento / Email"
				[ngClass]="{ 'is-invalid': submitted && form.get('user')?.errors }"
			/>
			<span *ngIf="submitted && form.get('user')?.errors" class="invalid-feedback">{{
				form.get('user')?.hasError('user') ? 'Ingrese documento / email válido.' : 'Ingrese documento / email.'
			}}</span>
		</div>
		
		<button type="button" (click)="request()" class="btn btn-primary">Solicitar</button>
	</form>
</section>
