<div class="container my-4 projects_page">
    <div class="container d-flex">
        <div class="col">

            <div class="row text-center">
                <h2>Actualización de correo</h2>
                <h5>Para mantenerse informado debe actualizar su correo electrónico.</h5>
            </div>
            <div class="mb-3 mt-3">
                <div class="container">
                    <form [formGroup]="form">
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                Email
                            </div>
                            <div class="col-sm-7">
                                <input class="form-control" type="text" formControlName="email"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                Confirmación email
                            </div>
                            <div class="col-sm-7">
                                <input class="form-control" type="text" formControlName="confirm_email"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="d-flex flex-row-reverse mt-3">
                <button type="button" class="btn btn-success me-3" (click)="onSubmit()" >Actualizar</button>
            </div>
        </div>
    </div>
</div>
