import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { environment } from 'src/environments/environment'

import { switchMap } from 'rxjs/operators'
import { AuthService } from 'src/app/services/auth.service'

@Injectable()
export class TokenInterceptorRequest implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return of(this.authService.currentUser()).pipe(
			switchMap((user) => {
				if (user.token && user.token != '' && request.url.includes(environment.apiUrl)) {
					request = request.clone({
						setHeaders: {
							Authorization: `Bearer ${user.token}`,
						},
					})
				}
				return next.handle(request)
			})
		)
	}
}

export let tokenInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: TokenInterceptorRequest,
	multi: true,
}
