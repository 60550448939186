import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

type stateResponse = {
	result: {
		provincia: string
	}[]
}
@Injectable({
	providedIn: 'root',
})
export class StateService {
	private readonly baseUrl = 'state/'
	constructor(private httpClient: HttpClient) {}

	public get(): Observable<stateResponse> {
		return this.httpClient.get(environment.apiUrl + this.baseUrl).pipe(map((x) => x as stateResponse))
	}
}
