import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'


@Injectable({
	providedIn: 'root',
})
export class UrlService {

	public GetResource(fileName: String): String {
		return environment.apiUrl + "resource/" + fileName;
	}

}
