import { Component } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ImagesService } from 'src/app/api/images.service'
import { GaleryModalComponent } from 'src/app/components/galery-modal/galery-modal.component'
import { tap } from 'rxjs';
import { Galleries, Gallery } from 'src/app/interfaces/gallery.interface';
import { ActivatedRoute } from '@angular/router';


@Component({
	selector: 'fff-galery-page',
	templateUrl: './galery-page.component.html',
	styleUrls: ['./galery-page.component.scss'],
})
export class GaleryPageComponent {
	public galeryContent:Gallery[] = []
	public images: string[][] = []
	initPosition:number = 0
	projecType: number = 0

	constructor(private ngbModalService: NgbModal, private imagesService: ImagesService,private activatedRouter: ActivatedRoute,) { }

	ngOnInit(): void {
		this.projecType= this.activatedRouter.snapshot.queryParams['proyectType']
		this.initPosition = this.projecType - 1
		this.imagesService.getImages().pipe(
			tap(
				(x:Galleries)=>{  this.galeryContent = x.galleries.map((y:Gallery) => ({
					description: y.description,
					images: y.images,
				}))}
			),
			tap( ()=> {this.buildImagesTab()})
		).subscribe()
	}

	buildImagesTab(){
		this.galeryContent.map( (a,index)=> {this.images[index] = a.images })
	}

	public openGaleryModal(imageURL: any): void {
		const modalRef = this.ngbModalService.open(GaleryModalComponent, { centered: true, size: 'lg' })
		modalRef.componentInstance.images = imageURL
	}

	changeTab(index:number){
		this.initPosition = index
		this.projecType = index+1
	}
}
