import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs'

export enum BreakpointEnum {
	mobile,
	tablet,
	desktop,
	widescreen,
	fullhd,
}

const calculateBreakpoint = (width: number): BreakpointEnum => {
	if (width < 576) {
		return BreakpointEnum.mobile
	}

	if (576 <= width && width < 992) {
		return BreakpointEnum.tablet
	}

	if (992 <= width && width < 1152) {
		return BreakpointEnum.widescreen
	}

	return BreakpointEnum.fullhd
}

@Injectable({
	providedIn: 'root',
})
export class UiService implements OnDestroy {
	private listenerSubscription: Subscription = new Subscription()
	private breakPoint: BehaviorSubject<BreakpointEnum> = new BehaviorSubject<BreakpointEnum>(BreakpointEnum.mobile)

	constructor() {
		this.breakPoint.next(calculateBreakpoint(window.screen.width))

		this.listenerSubscription = fromEvent(window, 'resize').subscribe((evt) => {
			this.breakPoint.next(calculateBreakpoint(window.screen.width))
		})
	}

	public currentBreakPoint(): Observable<BreakpointEnum> {
		return this.breakPoint.asObservable()
	}

	public ngOnDestroy(): void {
		this.listenerSubscription.unsubscribe()
	}
}
