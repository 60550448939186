import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { catchError, tap, throwError } from 'rxjs'
import { StateService } from 'src/app/api/state.service'
import { UserService } from 'src/app/api/user.service'
import { LoadingService } from 'src/app/services/loading.service'
import { matchValidator } from 'src/app/utils/forms-validators'

@Component({
	selector: 'fff-register-page',
	templateUrl: './register-page.component.html',
	styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit {
	public readonly steps: string[] = ['Paso A', 'Paso B', 'Paso C']
	public currentStep: number = 1
	public states: string[] = []

	public formStep1!: FormGroup
	public submittedStep1: boolean = false

	public formStep2!: FormGroup
	public submittedStep2: boolean = false

	public formStep3!: FormGroup
	public submittedStep3: boolean = false
	public minDate:NgbDateStruct = {year:1923,day:1,month:1}

	constructor(
		formBuilder: FormBuilder,
		private loadingService: LoadingService,
		private stateService: StateService,
		private userService: UserService,
		private datePipe: DatePipe,
		private router: Router
	) {
		this.formStep1 = formBuilder.group(
			{
				dniType: ['', [Validators.required]],
				dni: ['', Validators.required],
				repeatedDni: ['', Validators.required],
				email: ['', (Validators.required, Validators.email)],
				repeatedEmail: ['', (Validators.required, Validators.email)],
				password: ['', Validators.required],
				repeatedPassword: ['', Validators.required],
			},
			{
				validators: [matchValidator('dni', 'repeatedDni'), matchValidator('email', 'repeatedEmail'), matchValidator('password', 'repeatedPassword')],
			}
		)

		this.formStep2 = formBuilder.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			birthdate: ['', Validators.required],
			address: ['', Validators.required],
			zipCode: ['', Validators.required],
			city: ['', Validators.required],
			state: ['', Validators.required],
			nationality: ['', Validators.required],
			areaCodePhone: ['', Validators.required],
			numberPhone: ['', Validators.required],
			areaCodePhoneAlt: ['', Validators.required],
			numberPhoneAlt: ['', Validators.required],
		})

		this.formStep3 = formBuilder.group({
			univertisyDegree: ['', Validators.required],
			univertisyIssued: ['', Validators.required],
			graduationYear: ['', Validators.required],
			registerNumber: ['', Validators.required],
			workplace: ['', Validators.required],
			position: ['', Validators.required],
		})
	}

	public ngOnInit(): void {
		this.loadingService.show()

		this.stateService
			.get()
			.pipe(
				tap((x) => (this.states = x.result.map((y) => y.provincia))),
				tap(() => this.loadingService.hide()),
				catchError((e) => {
					this.loadingService.hide()
					return throwError(() => e)
				})
			)
			.subscribe()
	}

	public onSubmitStep1(): void {
		this.submittedStep1 = true

		if (!this.formStep1.valid) {
			return
		}

		this.currentStep++
	}

	public onSubmitStep2(): void {
		this.submittedStep2 = true

		if (!this.formStep2.valid) {
			return
		}

		this.currentStep++
	}

	public onBackStep2(): void {
		this.currentStep--
	}

	public onSubmitStep3(): void {
		this.submittedStep3 = true

		if (!this.formStep3.valid) {
			return
		}

		this.loadingService.show()

		this.userService
			.register({
				document_type: this.formStep1.get('dniType')?.value,
				document_number: this.formStep1.get('dni')?.value,
				email: this.formStep1.get('email')?.value,
				password: this.formStep1.get('password')?.value,
				last_name: this.formStep2.get('lastName')?.value,
				first_name: this.formStep2.get('firstName')?.value,
				birthdate: new Date(this.datePipe.transform(this.formStep2.get('birthdate')?.value, 'yyyy-MM-dd') as string),
				address: this.formStep2.get('address')?.value,
				zip_code: this.formStep2.get('zipCode')?.value,
				town: this.formStep2.get('city')?.value,
				state: this.formStep2.get('state')?.value,
				nationality: this.formStep2.get('nationality')?.value,
				area_code_phone_contact: this.formStep2.get('areaCodePhone')?.value,
				number_phone_contact: this.formStep2.get('numberPhone')?.value,
				area_code_phone_contact_alt: this.formStep2.get('areaCodePhoneAlt')?.value,
				number_phone_contact_alt: this.formStep2.get('numberPhoneAlt')?.value,
				university_degree: this.formStep3.get('univertisyDegree')?.value,
				university_issued: this.formStep3.get('univertisyIssued')?.value,
				graduation_year: this.formStep3.get('graduationYear')?.value,
				registry_number: this.formStep3.get('registerNumber')?.value,
				workplace: this.formStep3.get('workplace')?.value,
				position: this.formStep3.get('position')?.value,
			})
			.pipe(
				tap(() => this.loadingService.hide()),
				tap(() => this.router.navigate(['/login'])),
				catchError((e) => {
					this.loadingService.hide()
					return throwError(() => e)
				})
			)
			.subscribe()
	}

	public onBackStep3(): void {
		this.currentStep--
	}

	public inputNumberValidation(event: KeyboardEvent) {
		if (!event.code.includes('Digit')) {
			event.preventDefault()
		}
	}
}
