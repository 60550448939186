<section>
	<div class="container-fluid bg-primary hero-header">
		<div class="container">
			<div class="row gy-12 align-items-center">
				<div class="col-lg-12 text-center">
					<h1 class="text-white mb-3 animated slideInDown">Fundación Florencio Fiorini</h1>
					<h2 class="text-white mb-4 my-5 animated slideInDown">Nuestro Objeto</h2>
					<p class="text-white pb-5 animated fadeIn">
						Estimular y favorecer la educación, formación profesional e investigación científica,
						<br />
						especialmente en el campo de la salud humana y cooperar a la beneficencia pública.
					</p>
				</div>
			</div>
		</div>
	</div>
	<div class="container py-4 text-center">
		<h1 class="text-primary mb-4">Investigación científica</h1>
		<section class="mb-5">
			<div class="row text-center">
				<h3 class="text-primary">Convocatorias</h3>
			</div>
			<div class="pt-4">
				<div class="row">
					<div *ngFor="let call of calls">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-md-2 text-center">
										Cierre: {{ call.dueDate | date }}
									</div>
									<div class="col-md-2">
										<img *ngIf="call.type===1" src="assets/img/ProgBecaEstimulo.jpg" class="img-fluid rounded-start" alt="..." />
										<img *ngIf="call.type===2" src="assets/img/ProgSubsidio.jpg" class="img-fluid rounded-start" alt="..." />
										<img *ngIf="call.type===3" src="assets/img/ProgAnual.jpg"  class="img-fluid rounded-start" alt="..." />
										<img *ngIf="call.type===4" src="assets/img/ProgBienal.jpg" class="img-fluid rounded-start" alt="..." />
									</div>
									<div class="col-md-6 text-start">
										{{ call.title }}
									</div>
									<div class="col-md-2 col-lg-2">
										<div class="row mb-2">
											<div class="col-md-12">
												<button class="btn btn-primary" (click)="navToAnnouncement(call.type)">Inscripción</button>
											</div>
										</div>
										<div class="row mb-2">
											<div class="col-md-12">
												<button class="btn btn-primary" (click)="navToReview(call.type)">Reseña</button>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="mb-5">
			<h3 class="text-primary">Galería de imágenes</h3>
			<ngb-carousel [interval]="2000" [pauseOnHover]="true" [pauseOnFocus]="true" [showNavigationIndicators]="false">
				<ng-template ngbSlide *ngFor="let image of images">
					<div class="picsum-img-wrapper">
						<img [src]="image" alt="Random first slide" class="rounded" />
					</div>
				</ng-template>
			</ngb-carousel>
		</section>
		<section class="mb-4">
			<div class="row gy-3">
				<div class="col">
					<h3 class="text-primary">Programa de Apoyo a Escuelas Rurales</h3>
					 <a class="nav-link" aria-current="page" [routerLink]="['/escuelasrurales']"><img src="assets/img/EscuelasRurales.jpg" width="230px" alt="Random first slide" class="rounded" /></a>			
					 <button class="btn btn-primary mt-2" (click)="navToSchools()">Reseña</button>
				</div>
				<div class="col">
					<h3 class="text-primary">Programa de Apoyo al Bien Público</h3>
					<a class="nav-link" aria-current="page" [routerLink]="['/bienpublico']"><img src="assets/img/BienPublico.jpg" width="252px" alt="Random first slide" class="rounded" /></a>			
					<button class="btn btn-primary mt-2" (click)="navToPublicGood()">Reseña</button>
				</div>
			</div>
		</section>
	</div>
</section>
