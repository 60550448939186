import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { catchError, tap, throwError } from 'rxjs'
import { RecoveryService } from 'src/app/api/recovery.service'
import { LoadingService } from 'src/app/services/loading.service'
import { OkModalComponent } from '../../modules/shared/modals/ok-modal/ok-modal.component';

@Component({
	selector: 'fff-password-recovery',
	templateUrl: './password-recovery.component.html',
	styleUrls: ['./password-recovery.component.scss'],
})
export class PasswordRecoveryComponent {
	public form!: FormGroup
	public submitted: boolean = false

	constructor(
		private router: Router,
		formBuilder: FormBuilder,
		public recoveryService: RecoveryService,
		public loadingService: LoadingService,
		private modalService: NgbModal
	) {
		this.form = formBuilder.group({
			user: ['', Validators.required],
		})
	}

	public request(): void {
		this.submitted = true

		if (!this.form.valid) {
			return
		}

		this.loadingService.show()

		this.recoveryService.resetRequest(
				this.form.get('user')?.value
			).pipe(
				tap((response) => {
					this.showMessage(response.detail)
				}),
				tap(() => this.loadingService.hide()),
				catchError((response) => {
					this.loadingService.hide()
					this.showMessageError(response.error.detail)
					return throwError(() => response)
				})
			)
			.subscribe()
	
			
	}

	public showMessage(message: String){
		const modalRef = this.modalService.open(OkModalComponent, { centered: true, backdrop: 'static',windowClass:'confirm-modal' })
		modalRef.componentInstance.message = "El correo fue enviado a: " + message
		modalRef.closed.subscribe(() => {
			this.router.navigate(['/'])
		})
	}

	public showMessageError(message: String){
		const modalRef = this.modalService.open(OkModalComponent, { centered: true, backdrop: 'static',windowClass:'confirm-modal' })
		modalRef.componentInstance.message = message
		modalRef.closed.subscribe(() => { return })
	}

}
