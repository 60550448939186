import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { catchError, tap, throwError } from 'rxjs'
import { UserService } from 'src/app/api/user.service'
import { ResetEmailModalComponent } from 'src/app/modules/shared/modals/reset-email-modal/reset-email-modal.component'
import { AuthService } from 'src/app/services/auth.service'
import { LoadingService } from 'src/app/services/loading.service'

@Component({
	selector: 'fff-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
	public form!: FormGroup
	public submitted: boolean = false

	constructor(
		private router: Router,
		private authService: AuthService,
		formBuilder: FormBuilder,
		public userService: UserService,
		public loadingService: LoadingService,
		private ngbModalService: NgbModal
	) {
		this.form = formBuilder.group({
			user: ['', [Validators.required]],
			password: ['', Validators.required],
		})
	}

	public login(): void {
		this.submitted = true

		if (!this.form.valid) {
			return
		}

		this.loadingService.show()

		this.userService
			.login({
				user: this.form.get('user')?.value,
				password: this.form.get('password')?.value,
			})
			.pipe(
				tap((x) => {
					this.authService.loginUser({
						token: x.access_token,
						id: x.id_user,
						fullName: x.user_name,
						isAdmin: x.is_admin,
						adminLevel: x.admin_level,
						email: x.email
					})

					if(x.email=='' || x.must_update_email)
					{
						this.updateEmailPopup()
					}
					
					this.router.navigate(['/inscripciones'])
				}),
				tap(() => this.loadingService.hide()),
				catchError((e) => {
					this.loadingService.hide()
					return throwError(() => e)
				})
			)
			.subscribe()	
	}

	private updateEmailPopup() {
		const modalRef = this.ngbModalService.open(
			ResetEmailModalComponent, { centered: true, backdrop: 'static', size: 'lg' }
		)

	}
}
