<section class="container my-4">
	<div class="d-flex align-items-center flex-column py-4">
		<div class="row">
			<div class="col-md-2">
				<img src="assets/img/ProgSubsidio.jpg" class="img-fluid rounded-start" alt="..." />
			</div>
			<div class="col-md-10">
				<h2 class="display-4 text-primary"><strong>Subsidio para Investigación en Ciencias Biomédicas</strong></h2>
				<p class="my-2">
					Para profesionales argentinos en ciencias, con aplicación en medicina humana, que deseen realizar sus proyectos de investigación en el país. Se
					otorga en cooperación con la Academia Nacional de Medicina.
				</p>
			</div>
		</div>
	</div>
	<hr />
	<div class="alert alert-warning">
		<p>A partir del año 2018, se delimita la convocatoria a los subsidios según se indica:</p>
		<ul>
			<li>AÑOS PARES: a temas en CIENCIAS BIOMÉDICAS, excluido Oncología y Hematología</li>
			<li>AÑOS IMPARES: a temas en ONCOLOGÍA y HEMATOLOGÍA, excluido a otras especialidades biomédicas.</li>
		</ul>
	</div>
	<hr />
	<section>
		<div>
			<h4>Convocatoria a los Subsidios para Investigación en {{getInvestigacionTitulo()}}</h4>
			<b>(Excluido {{getExcluidoSubtitulo()}})</b>
		</div>
		<br />
		<div class="row">
			<div class="col-md-3"></div>
			<div class="col-md-3 text-center align-items-center py-3">
				<button class="btn btn-primary btn-lg mb-4"(click)="onNewInscription()" [disabled]="noProject">Inscribir Proyecto</button>
				<p *ngIf="!noProject">Cierre de Inscripción: <strong> {{firstDueDate}}</strong></p>
			</div>
			<div class="col-md-3 text-center align-items-center py-3">
				<button class="btn btn-primary btn-lg mb-4" [routerLink]="['/subsidio-review']">Reseña</button>
				<p>Reseña del programa</p>
			</div>
			<div class="offset-md-3"></div>
		</div>
	</section>
	<hr />
	<section>
		<p class="h4 text-primary my-4">
			La información que deberá suministrar para esta inscripción es:
		</p>
		<ul>
			<li><span class="fw-semibold">Título del trabajo de investigación</span>, y si lo hubiere, su subtítulo.</li>
			<li><span class="fw-semibold">Datos de la Institución de desarrollo</span> del trabajo de investigación.</li>
			<li>
				<span class="fw-semibold">Datos del Padrino</span> del trabajo de investigación. (Si el postulante fuese investigador Junior, deberá indicar los datos del tutor/responsable del proyecto. Si el postulante fuese investigador Senior, deberá indicar los datos de un profesional relacionado con el tema, el dato será referencial y se considerará como segundo contacto).
			</li>
			<li>
				En caso de experimentación con humanos: <span class="fw-semibold">Aprobación de un Comité de Ética; Modelo de Consentimiento Informado.</span>
			</li>
		</ul>
		<p>
			Para los adjuntos, se deberá respetar el idioma y el tamaño máximo de hoja indicado. Se deberá identificar el nombre del postulante en su margen
			superior y la letra deberá ser legible, de un tamaño no inferior a "8".
		</p>
		<ul>
			<li><span class="fw-semibold">Objetivos del trabajo de Investigación</span>. En idioma español. Máximo 1 pág. A4</li>
			<li><span class="fw-semibold">Resultados esperados de la investigación</span>. En idioma español. Máximo 1 pág. A4</li>
			<li><span class="fw-semibold">Antecedentes del conocimiento sobre el tema de la investigación</span>. En idioma español. Máximo 1 pág. A4</li>
			<li><span class="fw-semibold">Citas bibliográficas</span>. Máximo 1 pág. A4</li>
			<li><span class="fw-semibold">Material y Métodos a utilizar</span>. En idioma español. Máximo 1 pág. A4</li>
			<li>
				<span class="fw-semibold">Curriculum Vitae del postulante con las actividades de los últimos 5 años</span>. En idioma español. Máximo 4 pág. A4
			</li>
		</ul>
	</section>
	<hr />
	<section *ngFor="let item of subsidy">
		<h4 class="text-primary my-4">
			{{item.description}}
		</h4>
		<ul>
			<li><a target="_blank" href="{{item.afichePdfUrl}}">Afiche</a></li>
			<li><a target="_blank" href="{{item.termsAndConditionsPdfUrl}}">Bases y Condiciones</a></li>
		</ul>
	</section>
	<hr />
</section>
