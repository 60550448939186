<nav class="navbar navbar-expand-lg navbar-light fff-navbar">
	<div class="container-fluid">
		<a class="navbar-brand" href="#">
			<img src="assets/img/Logo.fw.png" alt="Logo" />
		</a>
		<button class="navbar-toggler rounded-pill" type="button" (click)="onClickToggler()">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="navbar-collapse collapse" #collapse="ngbCollapse" [(ngbCollapse)]="!show">
			<ul class="navbar-nav fff-navbar-nav">
				<li class="nav-item">
					<a class="nav-link" aria-current="page" [routerLink]="['/']">Home</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" aria-current="page" [routerLink]="['/fundacion']">La Fundación</a>
				</li>
				<li class="nav-item" *ngIf="isMobileOrTablet$ | async; else dropMenu">
					<button class="btn nav-link investigation-btn" aria-current="page" (click)="onClickInvestigation()">Investigación Científica</button>
					<ul class="navbar-nav ms-4" #collapse="ngbCollapse" [(ngbCollapse)]="!showInvestigationMenu">
						<li class="nav-item">
							<a class="nav-link" aria-current="page" [routerLink]="['/becaestimulo']">Beca estímulo</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" aria-current="page" [routerLink]="['/subsidio']">Subsidio</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" aria-current="page" [routerLink]="['/premioanual']"> Premio anual</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" aria-current="page" [routerLink]="['/premiobienal']">Premio bienal</a>
						</li>
					</ul>
				</li>

				<li class="nav-item">
					<a class="nav-link" aria-current="page" [routerLink]="['/escuelasrurales']">Escuelas Rurales</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" aria-current="page" [routerLink]="['/bienpublico']">Bien Público</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" aria-current="page" [routerLink]="['/contacto']">Contacto</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" aria-current="page" [routerLink]="['/inscripciones']">Inscripción</a>
				</li>
				<li class="nav-item" *ngIf="!(isUserLogged$ | async)">
					<a class="nav-link" aria-current="page" [routerLink]="['/login']">Login</a>
				</li>
			</ul>
		</div>
	</div>
</nav>

<nav *ngIf="isUserLogged$ | async" class="navbar navbar-light border-bottom py-3">
	<div class="container-fluid justify-content-end" *ngIf="user$ | async as user">
		<div>
			<fa-icon [icon]="['fas', 'user']" class="me-2"></fa-icon>
			<span>{{ user.fullName }}</span>
		</div>
		<button class="btn btn-link mx-4" (click)="goProfile()">Mis datos</button>
		<a [routerLink]="['app/admin/administracion']" *ngIf="user.isAdmin">Administración</a>
		<div class="btn mx-4" (click)="logout()">
			<fa-icon [icon]="['fas', 'right-from-bracket']"></fa-icon>
		</div>
	</div>
</nav>

<ng-template #dropMenu>
	<li ngbDropdown class="d-flex justify-content-center">
		<button type="button" class="btn nav-link investigation-btn investigation-dropdown-toggle" ngbDropdownToggle>
			<span class="text-white">Investigación Científica</span>
		</button>
		<div ngbDropdownMenu class="navbar-nav" #collapse="ngbCollapse" [(ngbCollapse)]="!showInvestigationMenu">
			<a ngbDropdownItem class="nav-link" aria-current="page" [routerLink]="['/becaestimulo']">Beca estímulo</a>
			<a ngbDropdownItem class="nav-link" aria-current="page" [routerLink]="['/subsidio']">Subsidio</a>
			<a ngbDropdownItem class="nav-link" aria-current="page" [routerLink]="['/premioanual']"> Premio anual</a>
			<a ngbDropdownItem class="nav-link" aria-current="page" [routerLink]="['/premiobienal']">Premio bienal</a>
		</div>
	</li>
</ng-template>
