import { Component, OnInit } from '@angular/core'
import { UrlService } from 'src/app/api/url.service'

@Component({
  selector: 'fff-public-good-page',
  templateUrl: './public-good-page.component.html',
  styleUrls: ['./public-good-page.component.scss']
})
export class PublicGoodPageComponent {

  public UrlService: UrlService

  constructor(private urlService: UrlService) { 
    this.UrlService = urlService
  }

}
