import { Component, OnInit } from '@angular/core';
import { UrlService } from 'src/app/api/url.service';

@Component({
  selector: 'fff-fundation-page',
  templateUrl: './fundation-page.component.html',
  styleUrls: ['./fundation-page.component.scss']
})
export class FundationPageComponent {

  public UrlService: UrlService

  constructor(private urlService: UrlService) {
    this.UrlService = urlService
  }

}
