<section class="container page my-4">
	<h4 class="display-4 text-primary">Convocatorias</h4>
	<hr />
	<div class="pt-4">
		<div class="box-div">
			<div class="row m-4 p-4" *ngFor="let call of calls" (click)="navToAnnouncement(call.id)">
				<div class="row">
					<div class="col-md-12">
					</div>
				</div>
				<div class="col-md-2">
					<img *ngIf="call.type===1" src="assets/img/ProgBecaEstimulo.jpg" class="img-fluid rounded-start" alt="..." />
					<img *ngIf="call.type===2" src="assets/img/ProgSubsidio.jpg" class="img-fluid rounded-start" alt="..." />
					<img *ngIf="call.type===3" src="assets/img/ProgAnual.jpg"  class="img-fluid rounded-start" alt="..." />
					<img *ngIf="call.type===4" src="assets/img/ProgBienal.jpg" class="img-fluid rounded-start" alt="..." />
				</div>
				<div class="col-md-10">
					<div class="row">
						<div class="col-md-12">
							<h5>{{ call.title }}</h5>
						</div>
						<div class="col-md-12">
							<span>Cierre: {{ call.dueDate | date }}</span>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</section>
