import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Galleries } from '../interfaces/gallery.interface';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(private httpClient:HttpClient) { }

  public getImages(): Observable<Galleries> {
    return  this.httpClient.get(environment.apiUrl + 'gallery/availables').pipe(map((item)=> item as Galleries))
  }

}