import { Component, OnInit } from '@angular/core'
import { LoadingService } from '../../services/loading.service';
import { CallService } from '../../api/call.service';
import { catchError, tap, throwError } from 'rxjs';
import { CallType } from 'src/app/enums/call-type.enum';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

type callModel = {
	id: number
	title: string
	dueDate: Date
	type: CallType
}

@Component({
	selector: 'fff-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
	
	public calls: callModel[] = []

	public images = ['assets/img/fff_1.jpg', 'assets/img/fff_2.jpg', 'assets/img/fff_3.jpg']

	constructor(private loadingService:LoadingService, private callService:CallService, private router: Router,  private authService: AuthService ) {}

	public ngOnInit(): void {
		this.loadingService.show()
		this.callService
			.getAvailables()
			.pipe(
				tap(() => this.loadingService.hide()),
				tap((x) => {
					this.calls = x.calls.map((y) => ({
						id: Number.parseInt(y.id),
						title: y.description,
						dueDate: y.due_date,
						type: y.type as CallType,
					}))
				}),
				catchError((e) => {
					this.loadingService.hide()
					return throwError(() => e)
				})
			)
			.subscribe()
	}


	public navToAnnouncement(id: number): void {
		
		if(id == 1){ /* beca estimulo */
			this.router.navigate(['/becaestimulo'])
		}
		if(id == 2){/* subsidio */
			this.router.navigate(['/subsidio'])
		}
		if(id == 3){ /* premio anual */
			this.router.navigate(['/premioanual'])
		}
		if(id == 4){ /* premio bienal */
			this.router.navigate(['/premiobienal'])
		}
	}

	public navToReview(id: number): void {
		
		if(id == 1){ /* beca estimulo */
			this.router.navigate(['/becaestimulo-review'])
		}
		if(id == 2){/* subsidio */
			this.router.navigate(['/subsidio-review'])
		}
		if(id == 3){ /* premio anual */
			this.router.navigate(['/premioanual-review'])
		}
		if(id == 4){ /* premio bienal */
			this.router.navigate(['/premiobienal-review'])
		}
	}

	public navToPublicGood(): void {
		this.router.navigate(['/bienpublico'])

	}

	public navToSchools(): void {
		this.router.navigate(['/escuelasrurales'])

	}

	
}
