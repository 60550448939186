import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs';
import { UserService } from 'src/app/api/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { OkModalComponent } from '../ok-modal/ok-modal.component';

@Component({
  selector: 'fff-reset-email-modal',
  templateUrl: './reset-email-modal.component.html',
  styleUrls: ['./reset-email-modal.component.scss']
})
export class ResetEmailModalComponent implements OnInit {

  public form: FormGroup
  public entity = {}
  
  constructor(
    private authService: AuthService,
    private userService: UserService,
    formBuilder: FormBuilder,
		private ngbModalService: NgbModal,
    private activeModal: NgbActiveModal,
    private loadingService: LoadingService) {
    
    this.form = formBuilder.group({
      email: ['',  [Validators.required, Validators.email]],
      confirm_email: ['',  [Validators.required, Validators.email]]
    })

  }
  
  public ngOnInit(): void {
    console.log("init!")
  }

  public showMessageError(message: String){
		const modalRef = this.ngbModalService.open(OkModalComponent, { centered: true, backdrop: 'static',windowClass:'confirm-modal' })
		modalRef.componentInstance.message = message
		modalRef.closed.subscribe(() => { return })
	}
  
  public onSubmit() {

    if (!this.form.valid) {
      return
    }

    const email = this.form.get('email')!.value
    const confirmEmail = this.form.get('confirm_email')!.value

    if(email !== confirmEmail) {
      this.showMessageError("Los correos ingresados deben coincidir")
      return;
    }

    this.loadingService.show()
    
    this.userService.update(this.authService.currentUser().id!, {
      email: this.form.get('email')?.value
    }).pipe(
      tap(() => { this.loadingService.hide() }),
      tap(()=>{this.activeModal.close(true)})
    ).subscribe()
  } 

}
