<section class="container my-4">
	<div class="d-flex align-items-center flex-column py-4">
		<div class="row">
			<div class="col-md-2">
				<img src="assets/img/ProgAnual.jpg" class="img-fluid rounded-start" alt="..." />
			</div>
			<div class="col-md-10">
				<h2 class="display-4 text-primary"><strong> Premio Anual sobre Avances en Especialidad Médica</strong></h2>
				<p class="my-2">
					Para profesionales argentinos en ciencias médicas que hayan logrado un avance en la especialidad médica de la convocatoria. Se otorga en cooperación con la Universidad del Salvador.
				</p>
			</div>
		</div>
	</div>
	<hr />
	<div class="row">
		<div class="col-md-3"></div>
		<div class="col-md-3 text-center align-items-center py-3">
			<button class="btn btn-primary btn-lg mb-4"(click)="onNewInscription()" [disabled]="noProject">Inscribir Proyecto</button>
			<p *ngIf="!noProject">Cierre de Inscripción: <strong> {{firstDueDate}}</strong></p>
		</div>
		<div class="col-md-3 text-center align-items-center py-3">
			<button class="btn btn-primary btn-lg mb-4" [routerLink]="['/premioanual-review']">Reseña</button>
			<p>Reseña del programa</p>
		</div>
		<div class="offset-md-3"></div>
	</div>

	<hr />
	<section>
		<p class="h4 text-primary my-4">
			La información que deberá suministrar para esta inscripción es:
		</p>
		<ul>
			<li><span class="fw-semibold">Seudónimo, Título y Subtítulo</span> del trabajo de investigación.</li>
			<li><span class="fw-semibold">Datos de la Institución de desarrollo</span> del trabajo de investigación.</li>
			<li>
				En caso de experimentación con humanos: <span class="fw-semibold">Aprobación de un Comité de Ética; Modelo de Consentimiento Informado.</span>
			</li>
			<li>
				<strong>Datos de cada uno de los Coautores</strong> (Apellido, Nombre, fecha nacimiento, tipo y número documento, domicilio, teléfono, email,
				título universitario, matrícula, lugar de trabajo, cargo.)
			</li>
			<li>
				<strong>1 ejemplar completo del trabajo de investigación</strong>, en idioma español, a espacio simple, en hoja de un tamaño máximo de 22cm x
				30cm, foliado e identificado con un seudónimo, remitidos a la secretaria de La Fundación, Cuba 1940 P8 Of. 803, C1428AED Ciudad de Buenos Aires
			</li>
		</ul>
	</section>
	<hr />
	<section *ngFor="let item of anualAwards">
		<h4 class="text-primary my-4">{{item.description}}</h4>
		<ul>
			<li><a href="{{item.afichePdfUrl}}">Afiche</a></li>
			<li><a href="{{item.termsAndConditionsPdfUrl}}">Bases y Condiciones</a></li>
		</ul>
	</section>
	<hr />
</section>
