import { Component } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { catchError, tap, throwError } from 'rxjs'
import { RecoveryService } from 'src/app/api/recovery.service'
import { UserService } from 'src/app/api/user.service'
import { OkModalComponent } from 'src/app/modules/shared/modals/ok-modal/ok-modal.component'
import { AuthService } from 'src/app/services/auth.service'
import { LoadingService } from 'src/app/services/loading.service'

@Component({
	selector: 'fff-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
	public form!: FormGroup
	public submitted: boolean = false
	public identifier?: number
	public code?: number

	constructor(
		private router: Router,
		formBuilder: FormBuilder,
		public userService: UserService,
		public loadingService: LoadingService,
		public recoveryService: RecoveryService,
		private route: ActivatedRoute,
		private modalService: NgbModal,
	) {
		this.form = formBuilder.group({
			newPassword: ['', [Validators.required]],
			validateNewPassword: ['', Validators.required],
		})


		this.route.params.subscribe(params => {
			this.identifier = params['userid']
			this.code = params['recoverycode']
		})

	}

	public cancelar(): void {
		this.router.navigate(['/'])
	}

	public login(): void {
		
		if (!this.form.valid) {
			return
		}

		this.loadingService.show()

		this.recoveryService.confirmReset(
			this.identifier!,
			this.code!,
			this.form.get('newPassword')?.value,
			this.form.get('validateNewPassword')?.value,
		).pipe(
			tap((response) => {
				this.showMessage(response.detail)
			}),
			tap(() => this.loadingService.hide()),
			catchError((response) => {
				this.loadingService.hide()
				this.showMessageError(response.error.detail)
				return throwError(() => response)
			})
		)
		.subscribe()
	}

	public showMessage(message: String){
		const modalRef = this.modalService.open(OkModalComponent, { centered: true, backdrop: 'static',windowClass:'confirm-modal' })
		modalRef.componentInstance.message = message
		modalRef.closed.subscribe(() => {
			this.router.navigate(['/'])
		})
	}

	public showMessageError(message: String){
		const modalRef = this.modalService.open(OkModalComponent, { centered: true, backdrop: 'static',windowClass:'confirm-modal' })
		modalRef.componentInstance.message = message
		modalRef.closed.subscribe(() => { return })
	}
}
