<section class="container mt-4">
	<div class="d-flex align-items-center flex-column py-4">
		<div class="row">
			<div class="col-md-2">
				<img src="assets/img/ProgBecaEstimulo.jpg" class="img-fluid rounded-start" alt="..." />
			</div>
			<div class="col-md-10">
				<h2 class="display-4 text-primary"><strong> Becas Estímulo para Investigación en Medicina</strong></h2>
				<p class="my-2">
					Para médicos argentinos de hasta 40 años, que deseen realizar en el país investigación básica o aplicada en medicina. Se otorga en cooperación con
					la Asociación Médica Argentina.
				</p>
			</div>
		</div>
	</div>
	<hr />
	<div class="row">
		<div class="col-md-3"></div>
		<div class="col-md-3 text-center align-items-center py-3">
			<button class="btn btn-primary btn-lg mb-4"(click)="onNewInscription()" [disabled]="noProject">Inscribir Proyecto</button>
			<p *ngIf="!noProject">Cierre de Inscripción: <strong> {{firstDueDate}}</strong></p>
		</div>
		<div class="col-md-3 text-center align-items-center py-3">
			<button class="btn btn-primary btn-lg mb-4" [routerLink]="['/becaestimulo-review']">Reseña</button>
			<p>Reseña del programa</p>
		</div>
		<div class="offset-md-3"></div>
	</div>

	<hr />
	<section>
		<p class="h4 text-primary my-4">
			La información que deberá suministrar para esta inscripción es:
		</p>
		<ul>
			<li><span class="fw-semibold">Título del trabajo de investigación</span>, y si lo hubiere, su subtítulo.</li>
			<li><span class="fw-semibold">Datos de la Institución de desarrollo</span> del trabajo de investigación.</li>
			<li>
				<span class="fw-semibold">Datos del Padrino</span> del trabajo de investigación. Se deberá indicar los datos del tutor/responsable del proyecto.
			</li>
			<li>
				En caso de experimentación con humanos: <span class="fw-semibold">Aprobación de un Comité de Ética; Modelo de Consentimiento Informado.</span>
			</li>
		</ul>
		<p>
			Para los adjuntos, se deberá respetar el idioma y el tamaño máximo de hoja indicado. Se deberá identificar el nombre del postulante en su margen
			superior y la letra deberá ser legible, de un tamaño no inferior a "8".
		</p>
		<ul>
			<li><span class="fw-semibold">Objetivos del trabajo de Investigación</span>. En idioma español. Máximo 1 pág. A4</li>
			<li><span class="fw-semibold">Resultados esperados de la investigación</span>. En idioma español. Máximo 1 pág. A4</li>
			<li><span class="fw-semibold">Antecedentes del conocimiento sobre el tema de la investigación</span>. En idioma español. Máximo 1 pág. A4</li>
			<li><span class="fw-semibold">Citas bibliográficas</span>. Máximo 1 pág. A4</li>
			<li><span class="fw-semibold">Material y Métodos a utilizar</span>. En idioma español. Máximo 1 pág. A4</li>
			<li>
				<span class="fw-semibold">Curriculum Vitae del postulante con las actividades de los últimos 5 años</span>. En idioma español. Máximo 4 pág. A4
			</li>
		</ul>
	</section>
	<section *ngFor="let item of scolarships">
		<h4 class="text-primary my-4">{{item.description}}</h4>
		<ul>
			<li><a href="{{item.afichePdfUrl}}" target="_blank">Afiche</a></li>
			<li><a href="{{item.termsAndConditionsPdfUrl}}">Bases y Condiciones</a></li>
		</ul>
	</section>
	<hr />
</section>
<br/>
