<section class="container my-4">
	<div class="d-flex align-items-center flex-column py-4">
		<div class="row">
			<div class="col-md-2">
				<img src="assets/img/ProgBienal.jpg" class="img-fluid rounded-start" alt="..." />
			</div>
			<div class="col-md-10">
				<h2 class="display-4 text-primary"><strong> Premio Benial sobre Investigación en Cáncer</strong></h2>
				<p class="my-2">
					Para profesionales argentinos en ciencias médicas que hayan efectuado en el país un trabajo de investigación sobre cáncer. Se otorga en cooperación
					con la Liga Argentina de Lucha contra el Cáncer.
				</p>
			</div>
		</div>
	</div>
	<section>
		<h4 class="text-primary my-4">Reseña</h4>
		<p>
			En el año 1991 se inició la cooperación con LALCEC en su lucha contra el cáncer mediante el estímulo a la investigación sobre esta enfermedad. Con el propósito de favorecer tanto la investigación básica como la aplicada, se han establecido dos premios bienales al mejor trabajo científico original e inédito sobre estos temas
		</p>
		<p>
			Los años pares: "Nuevos Desarrollos diagnósticos y/o terapéuticos en cáncer"
		</p>
		<p>
			Los años impares: "Investigación Básica sobre Cáncer"
		</p>
	</section>
	<div class="d-flex align-items-center flex-column py-4">
		<button class="btn btn-primary btn-lg mb-4" [routerLink]="['/premiobienal']">Inscripción</button>
	</div>
	<section>
		<h4 class="text-primary my-4">Premios otorgados desde 1991</h4>
		<ul>
			<li><a href="{{UrlService.GetResource('Premiados_LALCEC_91_a_hoy.pdf')}}">Lista de beneficiarios</a></li>
		</ul>
	</section>
	<section>
		<h4 class="text-primary my-4">Galería de imágenes</h4>
		<!--
		<ul>
			<li>Para ver la galería de imágenes <a [routerLink]="['../galeria']" [queryParams]="{proyectType: 2}">(haga click aqui)</a></li>
		</ul>
		-->
		<p>En construcción</p>
	</section>
	<hr />
</section>
