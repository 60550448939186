import { Component } from '@angular/core'
import { UrlService } from 'src/app/api/url.service'

@Component({
  selector: 'fff-schools-page',
  templateUrl: './schools-page.component.html',
  styleUrls: ['./schools-page.component.scss']
})
export class SchoolsPageComponent {

  public UrlService: UrlService

  constructor(private urlService: UrlService) { 
    this.UrlService = urlService
  }
}
