<section class="container mt-4">
	<div class="d-flex align-items-center flex-column py-4">
		<div class="row">
			<div class="col-md-2">
				<img src="assets/img/ProgBecaEstimulo.jpg" class="img-fluid rounded-start" alt="..." />
			</div>
			<div class="col-md-10">
				<h2 class="display-4 text-primary"><strong> Becas Estímulo para Investigación en Medicina</strong></h2>
				<p class="my-2">
					Para médicos argentinos de hasta 40 años, que deseen realizar en el país investigación básica o aplicada en medicina. Se otorga en cooperación con
					la Asociación Médica Argentina.
				</p>
			</div>
		</div>
	</div>
	<section>
		<h4 class="text-primary my-4">Reseña</h4>
		<p>
			El 3 de diciembre de 2002 la Fundación Florencio Fiorini y la Asociación Médica Argentina instituyeron un programa anual denominado "Becas Estímulo
			Florencio Fiorini" para investigación en Medicina, que tiene como objetivo dar apoyo económico para estímulo científico a médicos argentinos de
			hasta 40 años de edad, que deseen realizar en el país proyectos de investigación básica o aplicada en medicina humana.
		</p>
		<p>
			Cada año se convoca a participar de un concurso abierto de proyectos, de entre los que un jurado designado al efecto selecciona a los beneficiarios
			de las becas.
		</p>
		<p>Cada beca consiste en una suma de dinero a abonarse mensualmente durante un año.</p>
	</section>
	<div class="d-flex align-items-center flex-column py-4">
		<button class="btn btn-primary btn-lg mb-4" [routerLink]="['/becaestimulo']">Inscripción</button>
	</div>
	<section>
		<h4 class="text-primary my-4">Becas otorgadas desde 2003</h4>
		<ul>
			<li><a href="{{UrlService.GetResource('Listado_Becas_Estimulo_desde_2003.pdf')}}" target="_blank">Lista de beneficiarios</a></li>
		</ul>
	</section>
	<hr />
	<section>
		<h4 class="text-primary my-4">Galería de imágenes</h4>
		<ul>
			<!--<li>Para ver la galería de imágenes <a [routerLink]="['../galeria']" [queryParams]="{proyectType: 4}">(haga click aqui)</a></li>-->
		</ul>
		<p>En construcción</p>
	</section>
</section>
