<footer class="container-fluid bg-dark fff-footer">
	<div class="container py-5 px-lg-5 text-center">
		<h5 class="text-white mb-4">Contacto<span></span></h5>
		<p>
			<fa-icon [icon]="['fas', 'map-marked-alt']" class="me-3"></fa-icon>
			Cuba 1940 P8 Of. 803, C1428AED Ciudad de Buenos Aires.
		</p>

		<p><fa-icon [icon]="['fas', 'phone-alt']" class="me-3"></fa-icon>(011) 4780-2679 / (011) 6007-0742</p>
		<p><fa-icon [icon]="['fas', 'envelope']" class="me-3"></fa-icon>secretaria@fundacionfiorini.org.ar</p>
		<p><fa-icon [icon]="['fas', 'female']" class="me-3"></fa-icon>Alicia B. Luchetti</p>
		<div class="d-flex pt-12 justify-content-center">
			<a class="btn btn-outline-light btn-social" href=""><fa-icon [icon]="['fab', 'twitter']"></fa-icon></a>
			<a class="btn btn-outline-light btn-social ms-3" href=""><fa-icon [icon]="['fab', 'facebook-f']"></fa-icon></a>
			<a class="btn btn-outline-light btn-social mx-3" href=""><fa-icon [icon]="['fab', 'instagram']"></fa-icon></a>
			<a class="btn btn-outline-light btn-social" href=""><fa-icon [icon]="['fab', 'linkedin-in']"></fa-icon></a>
		</div>
	</div>
</footer>
